
/*! CSS Used from: https://www.certifixlivescan.com/wp-content/themes/certifix2019/assets/css/certifix-global.css?ver=1.1 ; media=all */
@media all{


#middle{float:left;width:100%;}
#footer{float:left;width:100%;background:#23262d;}
#footer .footer{padding-top:70px;padding-bottom:30px;}
#footer .footer .row{margin:0 -5px;}
#footer .footer .row:after,#footer .footer .row:before{content:"";display:table;clear:both;}
#footer .footer .widget{float:left;width:25%;margin-bottom:30px;padding:0 5px;font-family:"Montserrat";font-size:16px;font-weight:400;line-height:29px;vertical-align:top;}
#footer .footer h4{margin:0 0 15px 0;padding-bottom:10px;font-family:"Montserrat";font-size:16px;color:#ffffff;font-weight:700;line-height:20px;text-transform:uppercase;}
#footer .footer .contact-info{list-style:none;margin:0;padding:0 0 10px 0;}
#footer .footer .contact-info li{margin:10px 0 0 0;}
#footer .footer .contact-info li:first-child{margin-top:0;}
#footer .footer .contact-info li:before{padding-right:10px;font-family:"Material Design Icons";font-size:21px;color:#ffffff;vertical-align:middle;}
#footer .footer .contact-info li:nth-child(1):before{content:"\f3f2";}
#footer .footer .contact-info li:nth-child(2):before{content:"\f1ee";}
#footer .footer .contact-info li:nth-child(3):before{content:"\f361";}
#footer .footer .contact-info li a{color:#ffffff;text-decoration:none;}
#footer .footer .contact-info li a:hover{color:#08abe9;text-decoration:none;}
#footer .footer .menu{list-style:none;margin:0;padding:0 0 10px 0;}
#footer .footer .menu li{line-height:30px;}
#footer .footer .menu li a{color:#ffffff;text-decoration:none;}
#footer .footer .menu li a:hover{color:#08abe9;text-decoration:none;}
#footer .bottom{padding-top:20px;padding-bottom:50px;border-top:1px solid #3d4655;}
#footer .bottom:after,#footer .bottom:before{content:"";display:table;clear:both;}
#footer .bottom .text{float:left;width:auto;font-family:"Montserrat";font-size:14px;color:#ffffff;line-height:30px;}
#footer .bottom .text a{color:#ffffff;text-decoration:none;}
#footer .bottom .text a:hover{color:#ffffff;text-decoration:underline;}
#footer .bottom .review-logos{float:right;width:auto;}
#footer .bottom .review-logos img{padding-left:10px;}
#footer .bottom .social-icons{float:left;width:auto;clear:left;padding-top:5px;}
#footer .bottom .social-icons a{position:relative;display:inline-block;vertical-align:middle;margin:0 7px 0 0;width:24px;height:24px;text-align:center;-webkit-border-radius:50%;border-radius:50%;}
#footer .bottom .social-icons a:after,#footer .bottom .social-icons a:before{position:absolute;top:0;left:0;width:24px;height:24px;content:"";-webkit-border-radius:50%;border-radius:50%;-webkit-transition:opacity 150ms;transition:opacity 150ms;}
#footer .bottom .social-icons a:before{background-color:#fff;}
#footer .bottom .social-icons a:after{opacity:0;background-color:#08abe9;}
#footer .bottom .social-icons a:hover:after{opacity:1;}
#footer .bottom .social-icons a .icon{position:relative;z-index:1;width:24px;height:24px;fill:#333c49;}
#footer .bottom .social-icons a:hover .icon{fill:#ffffff;}
}

/*! CSS Used from: https://blog.certifixlivescan.com/wp-content/themes/clsblog/style.css?ver=5.1.4 ; media=all */
@media all{

  .text-right{text-align:right;}
  a{background-color:transparent;}
  img{border-style:none;}
  // *,*:before,*:after{-webkit-box-sizing:inherit;box-sizing:inherit;}
  ul{margin:0;padding:0;}
  ul{list-style:none;}
  img{height:auto;max-width:100%;}
  a{color:#2196f3;text-decoration:none;}
  a:visited{color:#0095fb;}
  a:hover,a:focus,a:active{color:#ffa200;}
  a:focus{outline:thin dotted;}
  a:hover,a:active{outline:0;}
  .main-navigation{clear:both;display:block;float:left;width:100%;}
  .main-navigation ul{display:none;list-style:none;margin:0;padding-left:0;}
  .main-navigation li{float:left;position:relative;}
  .main-navigation a{display:block;text-decoration:none;}
  .site-header:before,.site-header:after{content:"";display:table;table-layout:fixed;}
  .site-header:after{clear:both;}
  #masthead #menu-top{float:right;}
  #masthead #menu-top li{display:inline-block;margin:0 8px 0 0!important;padding:0 8px 0 0;border-right:1px solid #000;line-height:.2rem;}
  #masthead #menu-top li:last-child{margin-right:0!important;padding-right:0;border-right:0;}
  #masthead #menu-top li a{font-family:sans-serif;font-size:10px;color:#222;line-height:11px;margin:0;text-transform:uppercase;}
  #masthead .custom-logo{width:225px;}
  #masthead #primary-menu{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-flow:row wrap;-ms-flex-flow:row wrap;flex-flow:row wrap;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;}
  #masthead #primary-menu li{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;float:none;margin:15px 10px;}
  #masthead #primary-menu li a{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;padding:4px 0;font-size:.8125rem;text-transform:uppercase;color:#222;}
  #masthead #primary-menu li a:before{position:absolute;left:0;width:100%;bottom:-6px;height:2px;background-color:#08abe9;content:'';opacity:0;-webkit-transition:opacity .3s,-webkit-transform .3s;transition:opacity .3s,-webkit-transform .3s;transition:opacity .3s,transform .3s;transition:opacity .3s,transform .3s,-webkit-transform .3s;-ms-transform:translateY(10px);-webkit-transform:translateY(10px);transform:translateY(10px);}
  #masthead #primary-menu li a:hover{color:#08abe9;}
  #masthead #primary-menu li a:hover:before{opacity:1;-ms-transform:translateY(0);-webkit-transform:translateY(0);transform:translateY(0);bottom:-2px;}
  #masthead #primary-menu li:last-child{background-color:#08abe9;border-radius:50px;margin-right:0;}
  #masthead #primary-menu li:last-child a{padding:13px 18px;color:#fff;}
  #masthead #primary-menu li:last-child a:before{content:none;}
  .custom-logo-link{display:inline-block;}
  @media screen and (min-width:37.5em){
  .main-navigation ul{display:block;}
  }
  
  @media (max-width:1199px){
  #masthead #primary-menu li{margin:15px 5px;}
  #masthead #primary-menu li:last-child a{padding:13px 8px;}
  }
  @media (max-width:991px){
  #masthead #menu-top{display:none;}
  #masthead .custom-logo{width:180px;margin-top:10px;}
  #masthead #primary-menu{display:none;}
  }
  }

/*! CSS Used from: https://www.certifixlivescan.com/wp-content/uploads/responsive-menu/css/responsive-menu-1.css?ver=5.3.2 ; media=all */
@media all{
button#responsive-menu-button,#responsive-menu-container{display:none;-webkit-text-size-adjust:100%;}
@media screen and (max-width: 768px){
#responsive-menu-container{display:block;position:fixed;top:0;bottom:0;z-index:99998;padding-bottom:5px;margin-bottom:-5px;outline:1px solid transparent;overflow-y:auto;overflow-x:hidden;}
#responsive-menu-container.slide-right{transform:translateX(100%);-ms-transform:translateX(100%);-webkit-transform:translateX(100%);-moz-transform:translateX(100%);}
#responsive-menu-container,#responsive-menu-container:before,#responsive-menu-container:after,#responsive-menu-container *,#responsive-menu-container *:before,#responsive-menu-container *:after{box-sizing:border-box;margin:0;padding:0;}
#responsive-menu-container #responsive-menu-title{padding:25px 5%;}
#responsive-menu-container #responsive-menu{width:100%;}
#responsive-menu-container li.responsive-menu-item{width:100%;list-style:none;}
#responsive-menu-container li.responsive-menu-item a{width:100%;display:block;text-decoration:none;position:relative;}
#responsive-menu-container #responsive-menu li.responsive-menu-item a{padding:0 5%;}
button#responsive-menu-button{z-index:99999;display:none;overflow:hidden;outline:none;}
.responsive-menu-accessible{display:inline-block;}
.responsive-menu-accessible .responsive-menu-box{display:inline-block;vertical-align:middle;}
button#responsive-menu-button{padding:0 0;display:inline-block;cursor:pointer;transition-property:opacity, filter;transition-duration:0.15s;transition-timing-function:linear;font:inherit;color:inherit;text-transform:none;background-color:transparent;border:0;margin:0;overflow:visible;}
.responsive-menu-box{width:25px;height:16px;display:inline-block;position:relative;}
.responsive-menu-inner{display:block;top:50%;margin-top:-1px;}
.responsive-menu-inner,.responsive-menu-inner::before,.responsive-menu-inner::after{width:25px;height:2px;background-color:#ffffff;border-radius:4px;position:absolute;transition-property:transform;transition-duration:0.15s;transition-timing-function:ease;}
button#responsive-menu-button:hover .responsive-menu-inner,button#responsive-menu-button:hover .responsive-menu-inner::before,button#responsive-menu-button:hover .responsive-menu-inner::after,button#responsive-menu-button:focus .responsive-menu-inner,button#responsive-menu-button:focus .responsive-menu-inner::before,button#responsive-menu-button:focus .responsive-menu-inner::after{background-color:#ffffff;}
.responsive-menu-inner::before,.responsive-menu-inner::after{content:"";display:block;}
.responsive-menu-inner::before{top:-7px;}
.responsive-menu-inner::after{bottom:-7px;}
.responsive-menu-boring .responsive-menu-inner,.responsive-menu-boring .responsive-menu-inner::before,.responsive-menu-boring .responsive-menu-inner::after{transition-property:none;}
button#responsive-menu-button{width:35px;height:30px;position: absolute;top:10px;right:5%;background:#000000;}
button#responsive-menu-button:hover,button#responsive-menu-button:focus{background:#000000;}
button#responsive-menu-button .responsive-menu-box{color:#ffffff;}
button#responsive-menu-button{display:inline-block;transition:transform 0.5s, background-color 0.5s;}
#responsive-menu-container{width:75%;right:0;transition:transform 0.5s;text-align:left;background:#212121;}
#responsive-menu-container #responsive-menu-wrapper{background:#212121;}
#responsive-menu-container .responsive-menu-item-link,#responsive-menu-container #responsive-menu-title{transition:background-color 0.5s, border-color 0.5s, color 0.5s;}
#responsive-menu-container #responsive-menu-title{background-color:#08aae8;color:#ffffff;font-size:16px;text-align:left;}
#responsive-menu-container #responsive-menu-title:hover{background-color:#08aae8;color:#ffffff;}
#responsive-menu-container #responsive-menu > li.responsive-menu-item:first-child > a{border-top:1px solid #212121;}
#responsive-menu-container #responsive-menu li.responsive-menu-item .responsive-menu-item-link{font-size:16px;}
#responsive-menu-container #responsive-menu li.responsive-menu-item a{line-height:40px;border-bottom:1px solid #212121;color:#ffffff;background-color:#212121;height:40px;}
#responsive-menu-container #responsive-menu li.responsive-menu-item a:hover{color:#ffffff;background-color:#3f3f3f;border-color:#212121;}
#responsive-menu-container #responsive-menu li.responsive-menu-current-item > .responsive-menu-item-link{background-color:#08aae8;color:#ffffff;border-color:#08aae8;}
#responsive-menu-container #responsive-menu li.responsive-menu-current-item > .responsive-menu-item-link:hover{background-color:#08aae8;color:#ffffff;border-color:#08aae8;}
}
}

/*! CSS Used keyframes */
@-webkit-keyframes rotate{from{-webkit-transform:rotate(0deg);}to{-webkit-transform:rotate(360deg);}}
@-moz-keyframes rotate{from{-moz-transform:rotate(0deg);}to{-moz-transform:rotate(360deg);}}
@-ms-keyframes rotate{from{-ms-transform:rotate(0deg);}to{-ms-transform:rotate(360deg);}}
@keyframes rotate{from{transform:rotate(0deg);}to{transform:rotate(360deg);}}
/*! CSS Used fontfaces */
@font-face{font-family:"Material Design Icons";src:url("https://d1nqynwlup3d17.cloudfront.net/wp-content/themes/certifix2019/assets/fonts/materialdesignicons-webfont.eot?v=2.2.43");src:url("https://d1nqynwlup3d17.cloudfront.net/wp-content/themes/certifix2019/assets/fonts/materialdesignicons-webfont.eot#iefix&v=2.2.43") format("embedded-opentype"),url("https://d1nqynwlup3d17.cloudfront.net/wp-content/themes/certifix2019/assets/fonts/materialdesignicons-webfont.woff2?v=2.2.43") format("woff2"),url("https://d1nqynwlup3d17.cloudfront.net/wp-content/themes/certifix2019/assets/fonts/materialdesignicons-webfont.woff?v=2.2.43") format("woff"),url("https://d1nqynwlup3d17.cloudfront.net/wp-content/themes/certifix2019/assets/fonts/materialdesignicons-webfont.ttf?v=2.2.43") format("truetype"),url("https://d1nqynwlup3d17.cloudfront.net/wp-content/themes/certifix2019/assets/fonts/materialdesignicons-webfont.svg?v=2.2.43#materialdesigniconsregular") format("svg");font-weight:normal;font-style:normal;display:swap;}
@font-face{font-family:Lato;font-display:swap;font-style:normal;font-weight:700;src:url('https://fonts.gstatic.com/s/lato/v17/S6u9w4BMUTPHh6UVSwiPHQ.eot');src:local('Lato Bold'),   local('Lato-Bold'),   url('https://fonts.gstatic.com/s/lato/v17/S6u9w4BMUTPHh6UVSwiPGQ.woff2') format('woff2'),   url('https://fonts.gstatic.com/s/lato/v17/S6u9w4BMUTPHh6UVSwiPHw.woff') format('woff'),   url('https://fonts.gstatic.com/s/lato/v17/S6u9w4BMUTPHh6UVSwiPHA.ttf') format('truetype');}
@font-face{font-family:Lato;font-display:swap;font-style:normal;font-weight:400;src:url('https://fonts.gstatic.com/s/lato/v17/S6uyw4BMUTPHjx4wWg.eot');src:local('Lato Regular'),   local('Lato-Regular'),   url('https://fonts.gstatic.com/s/lato/v17/S6uyw4BMUTPHjx4wXg.woff2') format('woff2'),   url('https://fonts.gstatic.com/s/lato/v17/S6uyw4BMUTPHjx4wWA.woff') format('woff'),   url('https://fonts.gstatic.com/s/lato/v17/S6uyw4BMUTPHjx4wWw.ttf') format('truetype');}
@font-face{font-family:Montserrat;font-display:swap;font-style:normal;font-weight:700;src:url('https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_dJE3gnD_g.eot');src:local('Montserrat Bold'),   local('Montserrat-Bold'),   url('https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_dJE3gnD_g.woff2') format('woff2'),   url('https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_dJE3gnD-A.woff') format('woff'),   url('https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_dJE3gnD-w.ttf') format('truetype');}
@font-face{font-family:Montserrat;font-display:swap;font-style:normal;font-weight:400;src:url('https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459Wlhzw.eot');src:local('Montserrat Regular'),   local('Montserrat-Regular'),   url('https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2') format('woff2'),   url('https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459WlhzQ.woff') format('woff'),   url('https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459Wlhzg.ttf') format('truetype');}

@media (max-width: 767px){
  #header .main-nav, #header .top-bar{display:none;}
}

/*Mobile Menu*/
/*! CSS Used from: https://blog.certifixlivescan.com/wp-content/themes/clsblog/style.css?ver=5.1.4 ; media=all */
@media all{
  button{font-family:inherit;font-size:100%;line-height:1.15;margin:0;}
  button{overflow:visible;}
  button{text-transform:none;}
  button,[type="button"]{-webkit-appearance:button;}
  button::-moz-focus-inner,[type="button"]::-moz-focus-inner{border-style:none;padding:0;}
  button:-moz-focusring,[type="button"]:-moz-focusring{outline:1px dotted ButtonText;}
  button{color:#4c4c4c;font-family:Montserrat,sans-serif;font-size:16px;font-size:1rem;line-height:1.5;}
  // *,*:before,*:after{-webkit-box-sizing:inherit;box-sizing:inherit;}
  button{border:1px solid;border-color:#ccc #ccc #bbb;border-radius:3px;background:#e6e6e6;color:rgba(0,0,0,0.8);font-size:12px;font-size:.75rem;line-height:1;padding:.6em 1em .4em;}
  button:hover{border-color:#ccc #bbb #aaa;}
  button:active,button:focus{border-color:#aaa #bbb #bbb;}
  #responsive-menu-button{display:none;width:35px;height:30px;padding:5px;position: absolute;;top:15px;right:5%;background:#000;z-index:999999;border:0;border-radius:0;cursor:pointer;}
  #responsive-menu-button .responsive-menu-box{display:inline-block;vertical-align:middle;color:#fff;width:25px;height:16px;position:relative;}
  #responsive-menu-button .responsive-menu-box .responsive-menu-inner,#responsive-menu-button .responsive-menu-box .responsive-menu-inner:before,#responsive-menu-button .responsive-menu-box .responsive-menu-inner:after{width:25px;height:2px;background-color:#fff;border-radius:4px;position:absolute;-webkit-transition-property:-webkit-transform;transition-property:-webkit-transform;transition-property:transform;transition-property:transform,-webkit-transform;-webkit-transition-duration:.15s;transition-duration:.15s;-webkit-transition-timing-function:ease;transition-timing-function:ease;}
  #responsive-menu-button .responsive-menu-box .responsive-menu-inner{display:block;top:50%;margin-top:-1px;}
  #responsive-menu-button .responsive-menu-box .responsive-menu-inner:before,#responsive-menu-button .responsive-menu-box .responsive-menu-inner:after{content:'';display:block;}
  #responsive-menu-button .responsive-menu-box .responsive-menu-inner:before{top:-7px;}
  #responsive-menu-button .responsive-menu-box .responsive-menu-inner:after{bottom:-7px;}
  @media (max-width:991px){
  #responsive-menu-button{display:block !important;}
  }
  }
  /*! CSS Used fontfaces */
  @font-face{font-family:'Montserrat';font-style:normal;font-weight:400;font-display:swap;src:local("Montserrat Regular"),local("Montserrat-Regular"),url(https://blog.certifixlivescan.com/wp-content/themes/clsblog/fonts/montserrat/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format("woff2");unicode-range:U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;}
  @font-face{font-family:'Montserrat';font-style:normal;font-weight:700;font-display:swap;src:local("Montserrat Bold"),local("Montserrat-Bold"),url(https://blog.https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_dJE3gnD_g.woff2) format("woff2");unicode-range:U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;}

/*! CSS Used from: https://blog.certifixlivescan.com/wp-content/themes/clsblog/style.css?ver=5.1.4 ; media=all */
@media all{
  a{background-color:transparent;}
  // *,*:before,*:after{-webkit-box-sizing:inherit;box-sizing:inherit;}
  ul{margin:0;padding:0;}
  ul{list-style:none;}
  a{color:#2196f3;text-decoration:none;}
  a:visited{color:#0095fb;}
  a:hover,a:focus,a:active{color:#ffa200;}
  a:focus{outline:thin dotted;}
  a:hover,a:active{outline:0;}
  #mobile_navigation{width:75%;right:0;-webkit-transition:-webkit-transform .5s;transition:-webkit-transform .5s;transition:transform .5s;transition:transform .5s,-webkit-transform .5s;text-align:left;background:#212121;display:block;position:fixed;top:0;bottom:0;z-index:99998;padding-bottom:5px;margin-bottom:-5px;outline:1px solid transparent;overflow-y:auto;overflow-x:hidden;transform:translateX(100%);-ms-transform:translateX(100%);-webkit-transform:translateX(100%);-moz-transform:translateX(100%);}
  #mobile_navigation .navigation-title{background-color:#08aae8;color:#fff;font-size:16px;text-align:left;padding:25px 5%;text-transform:uppercase;}
  #mobile_navigation a{width:100%;display:block;text-decoration:none;padding:0 5%;position:relative;line-height:60px;border-bottom:1px solid #212121;color:#fff;background-color:#212121;height:60px;cursor:pointer;-webkit-transition:background-color .5s,border-color .5s,color .5s;transition:background-color .5s,border-color .5s,color .5s;}
  #mobile_navigation a:hover{color:#fff;background-color:#3f3f3f;border-color:#212121;}
  }

  #mobile_navigation.offscreen-menu {
    transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
}
#responsive-menu-button.mob-active .responsive-menu-inner {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
#responsive-menu-button.mob-active .responsive-menu-inner:after {
  bottom: 0;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
#responsive-menu-button.mob-active .responsive-menu-inner:before {
  opacity: 0;
}
#mobile_navigation {
    width: 75%;
    right: 0;
    -webkit-transition: -webkit-transform .5s;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s,-webkit-transform .5s;
    text-align: left;
    background: #212121;
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 99998;
    padding-bottom: 5px;
    margin-bottom: -5px;
    outline: 1px solid transparent;
    overflow-y: auto;
    overflow-x: hidden;
    transform: translateX(100%);
    -ms-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
}

/* Breadcrumbs */

/*! CSS Used from: https://www.certifixlivescan.com/wp-content/themes/certifix2019/assets/css/certifix-global.css?ver=1.1 ; media=all */

  /*! CSS Used from: https://www.certifixlivescan.com/wp-content/themes/certifix2019/assets/css/breadcrumbs.css?ver=1.1 ; media=all */
  @media all{
  #breadcrumbs{float:left;width:100%;padding:15px 0;background-color:#ecf4f6;}
  #breadcrumbs ol{padding:0;margin:0;list-style-type:none;}
  #breadcrumbs ol span{display:inline-block;padding:0;margin:0;background-image:none;font-size:15px;color:#8d8d8d;font-weight:400;text-transform:capitalize;}
  #breadcrumbs ol span a{color:#8d8d8d;text-decoration:none;}
  #breadcrumbs ol span a:hover{color:#08aae8;text-decoration:none;}
  #breadcrumbs ol span a:after{font-family:"Material Design Icons";content:"\f142";display:inline-block;padding-left:5px;padding-right:5px;vertical-align:middle;}
  }
  
  /*! CSS Used fontfaces */
  @font-face{font-family:"Material Design Icons";src:url("https://d1nqynwlup3d17.cloudfront.net/wp-content/themes/certifix2019/assets/fonts/materialdesignicons-webfont.eot?v=2.2.43");src:url("https://d1nqynwlup3d17.cloudfront.net/wp-content/themes/certifix2019/assets/fonts/materialdesignicons-webfont.eot#iefix&v=2.2.43") format("embedded-opentype"),url("https://d1nqynwlup3d17.cloudfront.net/wp-content/themes/certifix2019/assets/fonts/materialdesignicons-webfont.woff2?v=2.2.43") format("woff2"),url("https://d1nqynwlup3d17.cloudfront.net/wp-content/themes/certifix2019/assets/fonts/materialdesignicons-webfont.woff?v=2.2.43") format("woff"),url("https://d1nqynwlup3d17.cloudfront.net/wp-content/themes/certifix2019/assets/fonts/materialdesignicons-webfont.ttf?v=2.2.43") format("truetype"),url("https://d1nqynwlup3d17.cloudfront.net/wp-content/themes/certifix2019/assets/fonts/materialdesignicons-webfont.svg?v=2.2.43#materialdesigniconsregular") format("svg");font-weight:normal;font-style:normal;display:swap;}

/* Footer CSS*/

/*! CSS Used from: https://www.certifixlivescan.com/wp-content/themes/certifix2019/assets/css/certifix-global.css?ver=1.1 ; media=all */
@media all{
  
  #footer{float:left;width:100%;background:#23262d;}
  #footer .footer{padding-top:70px;padding-bottom:30px;}
  #footer .footer .row{margin:0 -5px;}
  #footer .footer .row:after,#footer .footer .row:before{content:"";display:table;clear:both;}
  #footer .footer .widget{float:left;width:25%;margin-bottom:30px;padding:0 5px;font-family:"Montserrat";font-size:16px;font-weight:400;line-height:29px;vertical-align:top;}
  #footer .footer h4{margin:0 0 15px 0;padding-bottom:10px;font-family:"Montserrat";font-size:16px;color:#ffffff;font-weight:700;line-height:20px;text-transform:uppercase;}
  #footer .footer .contact-info{list-style:none;margin:0;padding:0 0 10px 0;}
  #footer .footer .contact-info li{margin:10px 0 0 0;}
  #footer .footer .contact-info li:first-child{margin-top:0;}
  #footer .footer .contact-info li:before{padding-right:10px;font-family:"Material Design Icons";font-size:21px;color:#ffffff;vertical-align:middle;}
  #footer .footer .contact-info li:nth-child(1):before{content:"\f3f2";}
  #footer .footer .contact-info li:nth-child(2):before{content:"\f1ee";}
  #footer .footer .contact-info li:nth-child(3):before{content:"\f361";}
  #footer .footer .contact-info li a{color:#ffffff;text-decoration:none;}
  #footer .footer .contact-info li a:hover{color:#08abe9;text-decoration:none;}
  #footer .footer .menu{list-style:none;margin:0;padding:0 0 10px 0;}
  #footer .footer .menu li{line-height:30px;}
  #footer .footer .menu li a{color:#ffffff;text-decoration:none;}
  #footer .footer .menu li a:hover{color:#08abe9;text-decoration:none;}
  #footer .bottom{padding-top:20px;padding-bottom:50px;border-top:1px solid #3d4655;}
  #footer .bottom:after,#footer .bottom:before{content:"";display:table;clear:both;}
  #footer .bottom .text{float:left;width:auto;font-family:"Montserrat";font-size:14px;color:#ffffff;line-height:30px;}
  #footer .bottom .text a{color:#ffffff;text-decoration:none;}
  #footer .bottom .text a:hover{color:#ffffff;text-decoration:underline;}
  #footer .bottom .review-logos{float:right;width:auto;}
  #footer .bottom .review-logos img{padding-left:10px;}
  #footer .bottom .social-icons{float:left;width:auto;clear:left;padding-top:5px;}
  #footer .bottom .social-icons a{position:relative;display:inline-block;vertical-align:middle;margin:0 7px 0 0;width:24px;height:24px;text-align:center;-webkit-border-radius:50%;border-radius:50%;}
  #footer .bottom .social-icons a:after,#footer .bottom .social-icons a:before{position:absolute;top:0;left:0;width:24px;height:24px;content:"";-webkit-border-radius:50%;border-radius:50%;-webkit-transition:opacity 150ms;transition:opacity 150ms;}
  #footer .bottom .social-icons a:before{background-color:#fff;}
  #footer .bottom .social-icons a:after{opacity:0;background-color:#08abe9;}
  #footer .bottom .social-icons a:hover:after{opacity:1;}
  #footer .bottom .social-icons a .icon{position:relative;z-index:1;width:24px;height:24px;fill:#333c49;}
  #footer .bottom .social-icons a:hover .icon{fill:#ffffff;}
  }
  /*! CSS Used from: https://www.certifixlivescan.com/wp-content/themes/certifix2019/assets/css/skeleton.css?ver=1.1 ; media=all */
  @media all{
  
  @media only screen and (min-width: 959px) and (max-width: 1180px){

  #footer .footer .widget{width:50%;}
  #footer .footer .widget:nth-child(2n+3){clear:both;}
  }
  @media only screen and (min-width: 768px) and (max-width: 959px){
  
  #footer .footer .widget{width:50%;}
  #footer .footer .widget:nth-child(2n+3){clear:both;}
  }
  @media only screen and (max-width: 767px){
  
  #footer .footer .widget{width:100%;}
  #footer .footer .widget:last-child{margin-bottom:0;}
  #footer .bottom .text{width:100%;text-align:center;}
  #footer .bottom .text a{white-space:nowrap;}
  #footer .bottom .review-logos{width:100%;padding-top:10px;text-align:center;}
  #footer .bottom .review-logos img{height:40px;padding:0 5px;}
  #footer .bottom .social-icons{width:100%;padding-top:10px;text-align:center;}
  }
  @media only screen and (min-width: 480px) and (max-width: 767px){
  
  #footer .footer .widget{width:50%;}
  #footer .footer .widget:nth-child(2n+3){clear:both;}
  }
  
  }
  /*! CSS Used fontfaces */
  @font-face{font-family:Montserrat;font-display:swap;font-style:normal;font-weight:700;src:url('https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_dJE3gnD_g.eot');src:local('Montserrat Bold'),   local('Montserrat-Bold'),   url('https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_dJE3gnD_g.woff2') format('woff2'),   url('https://www.certifixlivescan.com/wp-content/cache/omgf-webfonts/JTURjIg1_i6t8kCHKm45_dJE3gnD-A.woff') format('woff'),   url('https://www.certifixlivescan.com/wp-content/cache/omgf-webfonts/JTURjIg1_i6t8kCHKm45_dJE3gnD-w.ttf') format('truetype');}
  @font-face{font-family:Montserrat;font-display:swap;font-style:normal;font-weight:400;src:url('https://www.certifixlivescan.com/wp-content/cache/omgf-webfonts/JTUSjIg1_i6t8kCHKm459Wlhzw.eot');src:local('Montserrat Regular'),   local('Montserrat-Regular'),   url('https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2') format('woff2'),   url('https://www.certifixlivescan.com/wp-content/cache/omgf-webfonts/JTUSjIg1_i6t8kCHKm459WlhzQ.woff') format('woff'),   url('https://www.certifixlivescan.com/wp-content/cache/omgf-webfonts/JTUSjIg1_i6t8kCHKm459Wlhzg.ttf') format('truetype');}
  @font-face{font-family:"Material Design Icons";src:url("https://d1nqynwlup3d17.cloudfront.net/wp-content/themes/certifix2019/assets/fonts/materialdesignicons-webfont.eot?v=2.2.43");src:url("https://d1nqynwlup3d17.cloudfront.net/wp-content/themes/certifix2019/assets/fonts/materialdesignicons-webfont.eot#iefix&v=2.2.43") format("embedded-opentype"),url("https://d1nqynwlup3d17.cloudfront.net/wp-content/themes/certifix2019/assets/fonts/materialdesignicons-webfont.woff2?v=2.2.43") format("woff2"),url("https://d1nqynwlup3d17.cloudfront.net/wp-content/themes/certifix2019/assets/fonts/materialdesignicons-webfont.woff?v=2.2.43") format("woff"),url("https://d1nqynwlup3d17.cloudfront.net/wp-content/themes/certifix2019/assets/fonts/materialdesignicons-webfont.ttf?v=2.2.43") format("truetype"),url("https://d1nqynwlup3d17.cloudfront.net/wp-content/themes/certifix2019/assets/fonts/materialdesignicons-webfont.svg?v=2.2.43#materialdesigniconsregular") format("svg");font-weight:normal;font-style:normal;display:swap;}

  /* Footer Responsive */
  
@media (min-width: 959px) and (max-width: 1180px){
  #footer .footer .widget{width:50%;}
  #footer .footer .widget:nth-child(2n+3){clear:both;}
}
@media (min-width: 768px) and (max-width: 959px){
  #footer .footer .widget{width:50%;}
  #footer .footer .widget:nth-child(2n+3){clear:both;}
}
@media (max-width: 767px){
  #footer .footer .widget{width:100%;}
  #footer .footer .widget:last-child{margin-bottom:0;}
  #footer .bottom .text {
    width: 100%;
    text-align: center;
  }
  #footer .bottom .review-logos {
    width: 100%;
    padding-top: 10px;
    text-align: center;
  }
  #footer .bottom .social-icons {
    width: 100%;
    padding-top: 10px;
    text-align: center;
  }
}
@media (min-width: 480px) and (max-width: 767px){
  #footer .footer .widget{width:50%;}
  #footer .footer .widget:nth-child(2n+3){clear:both;}
}

  