@import "../node_modules/bootstrap/scss/bootstrap";

$asbestos: #d3d3d3;
$prime-color: #08aae8;
$prime-color-hover: #0893c8;
$text-color: #232323;


// Define vars we'll be using
$brand-success: #08aae8;
$loader-size: 6em;
$check-height: $loader-size/2;
$check-width: $check-height/2;
$check-left: ($loader-size/6 + $loader-size/12);
$check-thickness: 3px;
$check-color: $brand-success;

body {
	font-family: "Lato", sans-serif;
}

.text-center {
	text-align: center;
}

.prime-color {
	color: $prime-color;
}

.form-title {
	font-weight: 700;
	width: 100%;
}

.box-wrapper {
	border: 1px solid #ddd;
	min-height: 300px;
	display: flex;
  align-items: center;
}

.c-progress-steps {
	margin: 0 0 1rem;
	list-style-type: none;
	font-family: Lato;
	padding: 0;
	
  li {
    position: relative;
    font-size: 14px;
    color: $asbestos;
    padding: 2px 0 2px 23px;
    
    a {
      color: inherit;
    }
    
    &.done {
      color: $prime-color;
    
      &:before {
        color: $prime-color;
        content: "\f058";
      }
    }
    
    &.current {
      color: $prime-color;
      
      &:before {
        color: $prime-color;
        content: "\f192";
      }
    }
    
    &:before {
      position: absolute;
      left: 0;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: 22px;
      background-color: #fff;
      content: "\f10c";
      
      @media all and (max-width: 600px) {
        top: calc(50% - 8px);
        font-size: 16px;
      }
    }
  }

	@media all and (min-width: 600px)  {
		display: table;
		list-style-type: none;
		margin: 30px auto;
		padding: 0;
		table-layout: fixed;
		width: 100%;
		
		li {
			display: table-cell;
			text-align: center;
			padding: 0;
			padding-bottom: 10px;
			white-space: nowrap;
			position: relative;
			border-left-width: 0;

			span {
				color: $text-color;
				font-size: 16px;
			}
				
      &.current {
        color: $prime-color;
        font-size: 16px;
        line-height: 14px;
        // border-bottom-color: $prime-color;
        
        &:before {
          color: $prime-color;
          content: "\f192";
        }
      }
      
      &:before {
        top: -24px;
        left: 50%;
        margin-left: -9px;
      }

      &:after {
        content: '';
        height: 2px;
        width: 100%;
        background-color: $asbestos;
        position: absolute;
        top: -14px;
        left: -50%;
        z-index: -1;
      }

      &:first-of-type:after {
        content: none;
      }

      &.done:after,
      &.current:after {
        background-color: $prime-color;
      }
		}
	}
}

.section-heading {
  position: relative;
	margin-bottom: 2.5rem;
	font-size: 1.567rem;
	font-weight: 700;
	
	&:after {
		content: '';
		height: 3px;
		position: absolute;
		background: $prime-color;
		width: 50px;
		left: 0;
		bottom: -10px;
	}
}

// Buttons


.btn-outline-primary, 
.btn-primary, 
.btn-success {
	color: #fff;
	font-size: 20px;
	background-color: $prime-color;
	border-color: $prime-color;
	border-radius: 25px;
	min-width: 130px;
	height: 48px;
	line-height: 30px;
	transition: all ease-in-out 0.5s;
	&:disabled {
			color: #fff;
			background-color: #809aa5;
			border-color: #809aa5;
			cursor: no-drop;
	}

	&:hover,
	&:not(:disabled):active {
		background-color: $prime-color-hover;
		border-color: $prime-color-hover;
	}
}

.btn-outline-primary {
	background-color: #fff;
	color: $prime-color;
	border-color: $prime-color;
	&:focus, &:hover {
		color: #fff;
	}
}

.btn-primary, .btn-success {
	float: right;
}

.btn-primary:not(:disabled):not(.disabled):focus,
.btn-success:not(:disabled):not(.disabled):focus,
.btn-outline-primary:not(:disabled):not(.disabled):focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus {
	border-color: $prime-color;
	background-color: $prime-color;
}

button.btn.btn-outline-secondary {
	border-color: $prime-color;
	color: $prime-color;
	
	.spinner-border {
		color: $prime-color !important;
	}

	&:hover {
		background: $prime-color;
		color: #fff;
	}
}

button.btn.btn-outline-secondary .spinner-border {
	color: #08aae8 !important;
}

// Bootstrap Grid overrides
.form-row>[class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

// MUI Date Picker Overrides
label + .MuiInput-formControl {
	margin-top: 0 !important;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.MuiInput-formControl:before, .MuiInput-formControl:after {
	content: none !important;
}

.MuiFormControl-marginNormal {
	margin-top: 8px !important;
	display: flex !important;
}

.MuiFormLabel-root,
.MuiFormLabel-root.Mui-error {
	color: inherit !important;
	position: relative !important;
	transform: initial !important;
}

.MuiInput-underline.Mui-error:after {
	transform: none !important;
	border-bottom-color: none !important;
}

// Fees Table 
.fees-heading {
	background: #d4d4d4;
	padding: 1rem 0.8rem 0.5rem;
	border-radius: 5px;
	margin-right: 15px;
	margin-left: 15px;
}

.fees-items {
	padding: 0.2rem 0.8rem 0.2rem;
	border-radius: 5px;
	margin-right: 15px;
	margin-left: 15px;
}

// MUI Datepicker Custom Errors
.custom-required-field .MuiInputBase-root, .Mui-error.MuiInput-formControl {
	border-color: #dc3545;
}

.MuiFormHelperText-root.Mui-error {
	color: #dc3545 !important;
}

.custom-required-field .invalid-feedback {
	display: block;
}

.custom-required-field > .MuiFormControl-root {
	margin-bottom: 0;
}

label + .MuiInput-formControl {
	padding-right: 2px;
}

// Locations Table Overrides
.ant-table-content {
	border-top-right-radius: 10px !important;
	border-top-left-radius: 10px !important;
	overflow: hidden;
}
.ant-table-thead th {
	background: $prime-color !important;
	color: #fff !important;
}

.ant-table-row td:last-of-type {
	white-space: break-spaces;
}

// Ant-Select Css

.ant-select {
	width: 100%;
	height: 38px;
	padding: 0.15rem 0.375rem 0.75rem;
	font-size: 0.9rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

	&:not(.ant-select-customize-input) .ant-select-selector {
		border: none;
		background-color: transparent;
	}

	&.ant-invalid-select {
    border: 1px solid #dc3444;
	}

	& ~ .ant-select-invalid-feedback {
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
	}

	.input-group:not(.has-validation) > .form-control:not(:last-child), .input-group:not(.has-validation) > .custom-select:not(:last-child), .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
	}
}


// success loader animation

.circle-loader {
	&.api-requesting {
		position: absolute;
    right: 155px;
    width: 50px;
    height: 50px;
    bottom: -12px;
	}
	margin: 2rem auto $loader-size/3.5;
  border: 2px dashed rgba(0, 0, 0, 0.2);
  border-left-color: $check-color;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: $loader-size;
	height: $loader-size;
	
}

.checkMark-show {
	display:block !important;
}
.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: $check-color;
  transition: border 500ms ease-out;
}

.checkmark {
  display: none;
  
  &.draw:after {
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
  }
  
  &:after {
    opacity: 1;
    height: $check-height;
    width: $check-width;
    transform-origin: left top;
    border-right: $check-thickness solid $check-color;
    border-top: $check-thickness solid $check-color;
    content: '';
    left: $check-left;
    top: $check-height;
    position: absolute;
  }
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: $check-width;
    opacity: 1;
  }
  40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
  100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
}

.info-tip {
	border: 1px solid;
	border-radius: 50%;
	width: 20px;
	display: inline-block;
	height: 20px;
	line-height: 20px;
	text-align: center;
	font-size: 0.7em;
	font-weight: 600;
	margin-left: 8px;
	cursor: pointer;
}

.ant-picker.datepicker-invalid {
	border: 1px solid red;
}

.ant-picker.datepicker-invalid ~ div {
	margin-top: 0.25rem;
	font-size: 80%;
	color: #dc3545;
}

.center-tabs {
  display: flex;
  justify-content: center;
}

.payment-button {
  background: url('/images/payment-sprite.jpg');
  width: 278px;
  height: 168px;
  background-repeat: no-repeat;
  border: none;

  &:focus {
    outline: none;
  }

  &.credit-card {
    background-position: 0 0;
    &:hover, &.active-method {
      background-position: 0 -168px;
    }
  }

  &.charge-to-account {
    background-position: -278px 0;
    &:hover, &.active-method {
      background-position: -278px -168px;
    }
  }


}

.account-number-label {
	margin-left : 250px;
	margin-top : 55px;
}

.account-number-field {
	margin-left: 30px;
	margin-top: 50px;
  }

.account-verified-text {
	color: green;
	text-align: right;
  }

